export function getSignalingUrl(peerId, roomId, subdomain, isHost)
{
	const hostname = window.config.serverHostname || window.location.hostname;
	const port =
		process.env.NODE_ENV !== 'production' ?
			window.config.developmentPort
			:
			window.config.productionPort;

	const url = `wss://${hostname}:${port}/?peerId=${peerId}&roomId=${roomId}&subdomain=${subdomain}&isHost=${isHost}`;

	return url;
}
